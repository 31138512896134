<template>
  <el-drawer title="抽盒详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :wrapperClosable="false" :close-on-press-escape="false" :modal-append-to-body="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">公告详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y pv-30">
        <el-form ref="form" label-width="60px" label-position="left">
          <el-form-item label="标题：">
            <el-input placeholder="请输入标题" v-model="input" clearable style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="描述：">
            <el-input type="textarea" placeholder="请输入内容" v-model="textarea" style="width: 800px;"></el-input>
          </el-form-item>
        </el-form>
        <div style="border: 1px solid #ccc;">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" />
        </div>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{richId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { IEditorConfig } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: 'richText',
  components: { Editor, Toolbar },
  data () {
    return {
      richId: '',//富文本id
      richType: 1,
      isDrawer: false,
      butLoading: false,
      input: '',//名称
      textarea: '',//描述
      //富文本
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    }
  },
  mounted () { },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // 初始化
    init (type, id) {
      this.richType = type
      this.input = ''
      this.html = ''
      this.textarea = ''
      if (id) {
        this.richId = id
        this.getDetails(id)
      } else {
        this.richId = ''
      }
      //配置菜单项
      const editorConfig = { MENU_CONF: {} }
      editorConfig.MENU_CONF['fontSize'] = {
        fontSizeList: [
          '8px',
          '9px',
          '10px',
          '12px',
          '14px',
          '16px',
          '18px',
          '20px',
          '22px',
          { name: '24px', value: '24px' },
        ]
      }
      this.editorConfig = editorConfig
      this.isDrawer = true
    },
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/notiAgrent/findNotiAgrentById?id=${id}`).then(({ data: res }) => {
        this.richId = res.data.id
        this.input = res.data.notiName
        this.html = res.data.content
        this.textarea = res.data.note
      })
    },
    //新增 修改
    submitForm () {
      console.log(this.input, this.html)
      if (this.input) {
        if (this.html) {
          this.butLoading = true
          let apiUrl = ''
          if (this.richId) {//修改
            apiUrl = '/notiAgrent/editNotiAgrent'
          } else {//新增
            apiUrl = '/notiAgrent/saveNotiAgrent'
          }
          this.$http.post(apiUrl, {
            id: this.richId,
            notiType: this.richType,
            notiName: this.input,
            content: this.html,
            note: this.textarea
          }).then(res => {
            this.$message({
              message: this.richId ? '保存成功' : '新增成功',
              type: 'success'
            })
            this.butLoading = false
            this.$emit('refresh')
            this.handleClose()
          }).catch(err => {
            this.butLoading = false
          })
        } else {
          this.$message.error('请填写标题内容')
        }
      } else {
        this.$message.error('请填写标题')
      }
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.agreement_redact {
  padding: 30px;
}
</style>